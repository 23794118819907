<template>
    <footer class="o-footer">
        <div class="o-footer__logo-container">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <NuxtLink v-if="footerData?.logo" class="o-footer__logo" to="/">
                            <img
                                width="160" height="40"
                                :src="footerData?.logo"
                                alt="Holgates Holiday Parks logo"
                                title="Holgates Holiday Parks" />
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>

        <div class="o-footer__primary">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 medium-4 cell">
                        <a
                            v-if="footerData?.company_email"
                            class="o-footer__email"
                            :href="`mailto:${footerData?.company_email}`">
                            <span v-html="footerData?.company_email"></span>
                        </a>

                        <a
                            v-if="footerData?.company_telephone"
                            class="o-footer__telephone"
                            :href="`tel:${footerData?.company_telephone}`">
                            <span v-html="footerData?.company_telephone"></span>
                        </a>

                        <div class="o-footer__socials">
                            <NuxtLink
                                v-if="footerData?.socials_facebook"
                                class="o-footer__social-link is-facebook"
                                :to="footerData?.socials_facebook"
                                title="Find us on Facebook"
                                target="_blank">
                                <i class="fa-brands fa-facebook"></i>
                            </NuxtLink>

                            <NuxtLink
                                v-if="footerData?.socials_instagram"
                                class="o-footer__social-link is-instagram"
                                :to="footerData?.socials_instagram"
                                title="Follow us on Instagram"
                                target="_blank">
                                <i class="fa-brands fa-instagram"></i>
                            </NuxtLink>

                            <NuxtLink
                                v-if="footerData?.socials_vimeo"
                                class="o-footer__social-link is-vimeo"
                                :to="footerData?.socials_vimeo"
                                title="Find us on Vimeo"
                                target="_blank">
                                <i class="fa-brands fa-vimeo"></i>
                            </NuxtLink>

                            <NuxtLink
                                v-if="footerData?.socials_tripadvisor"
                                class="o-footer__social-link is-tripadvisor"
                                :to="footerData?.socials_tripadvisor"
                                title="Find us on Tripadvisor"
                                target="_blank">
                                <svgo-logo-tripadvisor />
                            </NuxtLink>
                        </div>

                        <div
                            v-if="footerData?.company_description"
                            class="o-footer__company-description"
                            v-html="footerData?.company_description"></div>
                    </div>

                    <div v-if="footerData?.park_locations" class="small-12 medium-3 medium-offset-1 cell">
                        <div class="o-footer__parks">
                            <div class="o-footer__nav-title">Our Parks</div>

                            <div class="o-footer__parks-wrapper">
                                <ul
                                    v-for="(location, index) in footerData?.park_locations"
                                    :key="index"
                                    class="o-footer__park">
                                    <li class="o-footer__park-name" v-html="location.title"></li>

                                    <li v-for="(park, index) in location.parks" :key="index">
                                        <NuxtLink :to="park.link.url">
                                            <span v-html="park.link.title"></span>
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div v-if="footerData?.useful_links" class="small-12 medium-3 medium-offset-1 cell">
                        <div class="o-footer__useful-links">
                            <div class="o-footer__nav-title">Useful links</div>

                            <nav class="c-navigation c-navigation--vertical">
                                <ul>
                                    <li v-for="(link, index) in footerData?.useful_links" :key="index">
                                        <NuxtLink
                                            class="c-navigation__link"
                                            :to="link.link.url"
                                            :target="link.link.target">
                                            <span v-html="link.link.title"></span>
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="o-footer__ancillary">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div v-if="footerData?.awards" class="o-footer__awards">
                            <div
                                v-for="(award, index) in footerData?.awards"
                                :key="index"
                                class="o-footer__award"
                                v-if="award?.attachments?.['large']"
                                v-html="award.attachments['large']"></div>
                        </div>

                        <div class="o-footer__copyright">
                            <b
                                >&copy; {{ footerData?.company_founding_year }} -
                                {{ new Date().getFullYear() }}
                                {{ footerData?.company_name }}</b
                            >. Company No: {{ footerData?.company_number }} | VAT No:
                            {{ footerData?.vat_number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
    const footerData = ref(null);

    const getFooterData = async () => {
        const { data } = await useFetch(`/api/wp/components/footer`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {
            // console.log('No data found for the given route:', route.fullPath);

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        // console.log("Page Data: ", data.value);

        footerData.value = data.value;
    };

    await getFooterData();
</script>
