export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[{"rel":"preload","as":"style","href":"https://use.typekit.net/etg3uhr.css","onload":"this.onload=null;this.rel='stylesheet'"},{"rel":"stylesheet","href":"https://use.typekit.net/etg3uhr.css","media":"print","onload":"this.media='all'"}],"style":[],"script":[{"src":"https://kit.fontawesome.com/1fbba75bf5.js","crossorigin":"anonymous","data-search-pseudo-elements":true,"defer":true},{"src":"https://cdn-cookieyes.com/client_data/9dacb9ab728c7fcc1b11533d/script.js","id":"cookieyes","defer":true}],"noscript":[{"children":"<link rel=\"stylesheet\" href=\"https://use.typekit.net/etg3uhr.css\">"}],"htmlAttrs":{"lang":"en"},"title":"Holgates Holiday Parks in Cumbria"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false